// NPM Requirements
import { push } from 'connected-react-router';

let Auth = null;
if (typeof window === 'object') {
  Auth = require('j-toker/src/j-toker');
}

// Actions
import { createNotice } from './noticeActions';

export const USER_ACCOUNT_UPDATED = 'USER_ACCOUNT_UPDATED';

export const USER_TOKEN_VALIDATED = 'USER_TOKEN_VALIDATED';
export const USER_TOKEN_VALIDATING = 'USER_TOKEN_VALIDATING';

export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_REGISTERING = 'USER_REGISTERING';
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const PASSWORD_RESET_REQUESTING = 'PASSWORD_RESET_REQUESTING';
export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED';
export const PASSWORD_RESET_REQUEST_FAILED = 'PASSWORD_RESET_REQUEST_FAILED';

export const PASSWORD_CHANGING = 'PASSWORD_CHANGING';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';


// Account Update

export function userAccountUpdated(response) {
  return {
    type: USER_ACCOUNT_UPDATED,
    response
  };
}


// Token Validation

export function userTokenValidating() {
  return {
    type: USER_TOKEN_VALIDATING
  };
}

export function userTokenValidated(response) {
  return {
    type: USER_TOKEN_VALIDATED,
    response
  };
}

export function userValidateToken() {
  if (!Auth) return {};
  return dispatch => {
    dispatch(userTokenValidating());
    Auth.validateToken()
      .then((response) => {
        dispatch(userTokenValidated(response));
      })
      .fail((response) => {
        dispatch(push('/login'));
      });
  };
}


// Registration

export function userRegistering() {
  return {
    type: USER_REGISTERING
  };
}

export function userRegistered() {
  return {
    type: USER_REGISTERED
  };
}

export function userRegisterFailed(error) {
  return {
    type: USER_REGISTER_FAILED,
    message: error
  };
}

export function userRegister(data) {
  if (!Auth) return {};
  return dispatch => {
    dispatch(userRegistering());
    const promise = Auth.emailSignUp({
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      catering_user: data.catering_user,
      password: data.password,
      password_confirmation: data.password_confirmation
    });

    promise.then((response) => {
      dispatch(userRegistered());
    });

    promise.catch((response) => {
      dispatch(userRegisterFailed(response.data.errors.full_messages[0]));
    });

    return promise;
  };
}


// Log in / Log out

export function userLoggingIn() {
  return {
    type: USER_LOGGING_IN
  };
}

export function userLoginFailed(message) {
  return {
    type: USER_LOGIN_FAILED,
    message
  };
}

export function userLoggedIn(data) {
  return {
    type: USER_LOGGED_IN,
    userData: data
  };
}

export function userLoggedOut() {
  return {
    type: USER_LOGGED_OUT
  };
}

export function userLogout() {
  if (!Auth) return {};
  return dispatch => {
    Auth.signOut().then(() => {
      window.location.href = window.location.origin + '/login';
    });
  };
}

export function userLogin(data, history) {
  if (!Auth) return {};
  return dispatch => {
    dispatch(userLoggingIn());
    return Auth.emailSignIn(data)
      .then((response) => {
        if (response.data.admin) {
          history.push('/admin');
        } else if (response.data.catering_user) {
          history.push('/catering/dashboard');
        } else {
          if (response.data.trucks && response.data.trucks[0]) {
            history.push('/vendor/truck/' + response.data.trucks[0].slug);
          } else {
            history.push('/vendor/truck/new');
          }
        }
      })
      .fail((response) => {
        dispatch(userLoginFailed(response.data.errors[0]));
      });
  };
}

export function userOAuthLogin(options, history) {
  if (!Auth) return {};
  return dispatch => {
    dispatch(userLoggingIn());
    Auth.oAuthSignIn(options)
      .then((response) => {
        if (response.admin) {
          history.push('/admin');
        } else if (response.catering_user) {
          history.push('/catering/dashboard');
        } else {
          if (response.trucks && response.trucks[0]) {
            history.push('/vendor/truck/' + response.trucks[0].slug);
          } else {
            history.push('/vendor/truck/new');
          }
        }
      })
      .fail((response) => {
        dispatch(userLoginFailed('There was an error logging you in'));
      });
  };
}


// Password Reset

export function passwordResetRequesting() {
  return {
    type: PASSWORD_RESET_REQUESTING
  };
}

export function passwordResetRequested() {
  return {
    type: PASSWORD_RESET_REQUESTED
  };
}
export function passwordResetRequestFailed(message) {
  return {
    type: PASSWORD_RESET_REQUEST_FAILED,
    message
  };
}

export function requestPasswordReset(email) {
  if (!Auth) return {};
  return dispatch => {
    dispatch(passwordResetRequesting());
    Auth.requestPasswordReset({ email })
      .then(() => {
        dispatch(passwordResetRequested());
      })
      .fail((response) => {
        dispatch(passwordResetRequestFailed(response.data.errors[0]));
      });
  };
}


// Password Change

export function passwordChanging() {
  return {
    type: PASSWORD_CHANGING
  };
}

export function passwordChanged() {
  return {
    type: PASSWORD_CHANGED
  };
}

export function passwordChangeFailed(message) {
  return {
    type: PASSWORD_CHANGE_FAILED,
    message
  };
}

export function changePassword(data) {
  if (!Auth) return {};
  return dispatch => {
    dispatch(passwordChanging());
    return Auth.updatePassword({
      password: data.password,
      password_confirmation: data.password_confirmation
    })
      .then(() => {
        dispatch(passwordChanged());
        dispatch(createNotice('Password Updated!'));
      })
      .fail((response) => {
        dispatch(passwordChangeFailed(response.data.errors.full_messages[0]));
      });
  };
}
